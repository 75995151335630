import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

// my imports
import { Autocomplete } from '@mui/material';
import { Auth, API } from 'aws-amplify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

import titles from '../titles.json';
import VideoEngineShaka from '../videoEngineShaka.js';

import { useEffect } from 'react';

// date picker
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { Radio, RadioGroup } from '@mui/material';

// for sharing video
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';

import FormControlLabel from '@mui/material/FormControlLabel';

// new video packages
import ReactDOM from 'react-dom';
import VideoPlayer from '../VideoPlayer/VideoPlayer.js';
import '../VideoPlayer/videoPlayer.css';

export default function Playback(props) {
  const [searchedClubSelection, setClubSelection] = React.useState('');
  const [cameraList, setCameraList] = React.useState([]);
  const [hlsurl, setHlsUrl] = React.useState('');
  const [displayVideo, setDisplayVideo] = React.useState('false');
  const [selectedPlayback, setPlayback] = React.useState('');
  const [upcomingPlaybacks, setUpcomingPlaybacks] = React.useState([]);
  const [pastPlaybacks, setPastPlaybacks] = React.useState(undefined);
  
  // used for downloads and sharing. 
  const [older2Hr, setOlder2Hr] = React.useState(undefined);
  const [selectedDownload, setDownload] = React.useState('');
  const [disableDownload, setDisableDownload] = React.useState('false');

  // for sharing only
  const [sharingUrl, setSharingUrl] = React.useState(undefined);

   //FIX REFRESH OF BOTH VIEWS AFTER SUBMITTED FOR NEW PLAYBACK.  ALSO REMOVE VIDEO IF CLICKING BETWEEN UPCOMING & AVAILABLE
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    console.log(`useEffect in playbacks props.userData ${JSON.stringify(props.userData)} `);
    //setUserData(props.userData);
  }, [props.topNavSelectedId]);

  useEffect(() => {
    //console.log(`useEffect in memberships with props ${JSON.stringify(props)}`);
    
    // add API to get userData (if not already in props aand clubData. Not yet here.
      // {fill in}
    // then create clubsList for clubs tab that user is approved / pending for
    
    if(loading) {
      return undefined
    }
    setLoading(true);
    
    (async () => {
      console.log(`playback props ${JSON.stringify(props)}`)
      let playbacks = await getUsersPlaybacks();
      console.log(`API response getUsersPlaybacks is ${JSON.stringify(playbacks)}`);
    })();

    return () => {
      let a = null;
    };
  }, [loading]);

  const getUsersPlaybacks = async event => {
    try {
      console.log("getUsersPlaybacks ...");
      var now = new Date();
      var less14Days = new Date();
      less14Days = less14Days.setDate(less14Days.getDate() - 14);
      console.log(` less14 days is ${less14Days}`);
      // range all, past 14 days, upcoming
      var path = `videos?type=userPlaybacks`;
      let playbacks = await API.get("videos", path);
      var upcoming = [];
      var past = [];
      // older2Hr is really just older than 40 min as we changed backend from event bridge to wait state machine
      var older2Hr = [];
      let i = 0;
      var sub = props.sub;
      var endDateTime, startDateTime;
      while (i < playbacks.length) {
        endDateTime = new Date(playbacks[i].endDateTime);
        startDateTime = new Date(playbacks[i].startDateTime);
        if (startDateTime > now) {
          console.log('pusing upcoming playback item');
          upcoming.push(playbacks[i]);
        } else if (startDateTime > less14Days) {
          console.log(`pushing past playback item`);
          past.push(playbacks[i]);
        } 
        
        if (playbacks[i].mergedVideoFile !== undefined) {
          console.log(`pushing older than 14 days item`);
          older2Hr.push(playbacks[i]);
        } else {
          console.log(`mergedVideoFile not yet available`);
        }
        i++;
      }
      console.log(`upcoming ${JSON.stringify(upcoming)}`);
      console.log(`past ${JSON.stringify(past)}`);
      console.log(`older than 2hr ${JSON.stringify(older2Hr)}`);
      setUpcomingPlaybacks(upcoming);
      setPastPlaybacks(past);
      setOlder2Hr(older2Hr);
      if (!!playbacks) {
        console.log(`returning from getUsersPlaybacks`);
        return playbacks;
      } else {
        console.log("getUsersPlaybacks came back empty");
        return ;
      }
    } catch (e) {
      console.log("getUsersPlaybacks error is: ", e);
        alert(e.message);
    }
  }

  const getDownload = async (event) => {
    setDisableDownload(true);
    setSharingUrl(undefined);
    var path;
    console.log(`downloading presigned url for video clip... on stream ${selectedDownload.stream}`)
    path = `videos?streamName=${selectedDownload.stream}&mergedVideoFile=${selectedDownload.mergedVideoFile}&type=download`;
    console.log(`sending api with path ${path}`);
    let url = await API.get("videos", path);
    if (!!url.preSignedUrl) {
      
      //const response = await fetch(url.preSignedUrl);
      console.log(`fetch result ${url.preSignedUrl}`)
      var signedURL = url.preSignedUrl;
      console.log(`signedURL ${signedURL}`)
      //const blob = await signedURL.blob();

      
      //var objectUrl = URL.createObjectURL(signedURL);
      if(event.sharing) {
        console.log('sharing');
        setSharingUrl(signedURL);
      } else {
        console.log('downloading');
        const link = document.createElement("a");
        link.href = signedURL;
        link.download = 'yourGameTimeVideo.mp4';
        link.click();
      }
        
      
      /*
      
      var preSignedUrl = url.preSignedUrl;

      console.log(`url is ${JSON.stringify(preSignedUrl)}`);
      
      const link = document.createElement("a");
      link.href = preSignedUrl;
      link.download = 'yourGameTimeVideo.mp4';
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);*/
      await new Promise(r => setTimeout(r, 10000));
      setDisableDownload(false)
    } else {
      console.log("url info came back empty");
    }
  }

  // live to live is a issue
  const getClip = async (event) => {
    if (selectedPlayback === '') {
      return;
    } else {
      try {
        var path;
        console.log(`getting video clip... with mode: ${event.mode} on stream ${selectedPlayback.stream}`);
        var startingDate, endingDate;
        if (event.mode === 'ON_DEMAND') {
          var startingDate = new Date(selectedPlayback.startDateTime);
          var endingDate = new Date(selectedPlayback.endDateTime);
          //startingDate = new Date(Date.now() - 3600000);
          //endingDate = new Date(Date.now() - 330000);
          path = `videos?key1=${startingDate.toISOString()}&key2=${endingDate.toISOString()}&streamName=${selectedPlayback.stream}&mode=${event.mode}&type=hls`;
          console.log(`sending api with path ${path}`);
        } else if (event.mode === 'LIVE_REPLAY') {
          console.log('live replay');
          var potentialStartingDate = new Date(Date.now() - 3600000);
          var startingDate = new Date(selectedPlayback.startDateTime);
          var endingDate = new Date(selectedPlayback.endDateTime);
          var queryStartDateTime;
          if (potentialStartingDate < startingDate) {
            queryStartDateTime = startingDate;
          } else {
            queryStartDateTime = potentialStartingDate;
          }
          path = `videos?key1=${queryStartDateTime.toISOString()}&key2=${endingDate.toISOString()}&streamName=${selectedPlayback.stream}&mode=${event.mode}&type=hls`;
          console.log(`query for starting date for live is ${startingDate}`);
          
        } else {
          path = `videos?streamName=${selectedPlayback.stream}&mode=${event.mode}&type=hls`;
        }
        let url = await API.get("videos", path);
        console.log(`result ${JSON.stringify(url)}`);
        if (!!url.hlsUrl) {
          console.log(`url is ${JSON.stringify(url.hlsUrl)}`);
          setHlsUrl(url.hlsUrl);
          await new Promise(r => setTimeout(r, 3000)); 
          setDisplayVideo(true);
        } else {
          console.log("url info came back empty");
        }
      } catch (e) {
        console.log("url error is: ", e);
          alert(e.message);
      }
    }
    
  }

    // live to live is a issue
    const deletePlayback = async () => {
      if (selectedPlayback === '') {
        return;
      } else {
        try {
          var path;
          console.log(`deleting playback... on stream ${JSON.stringify(selectedPlayback)} `);
          var startingDate;
          var startingDate = new Date(selectedPlayback.startDateTime);
          path = `videos?startDateTime=${startingDate.toISOString()}&streamName=${selectedPlayback.stream}&type=deletePlayback&stepMachineExArn=${selectedPlayback.stpExArn}`;
          console.log(`sending api with path ${path}`);
          let response = await API.post("videos", path);
          console.log(`result ${JSON.stringify(response)}`);
          if (!!response) {
            console.log(`url is ${JSON.stringify(response)}`);
            alert('playback removed');
          } else {
            console.log("response info came back empty");
          }
        } catch (e) {
          console.log("url error is: ", e);
            alert(e.message);
        }
      }
    }

  const switchCamera = (e) => {
    console.log(`switched camera ${e.event.target.value}, ${e.start}, ${e.end} ${e.streamId} ${e.stpExArn}`);
    setPlayback({stream: e.streamId, startDateTime: e.start, endDateTime: e.end, stpExArn: e.stpExArn});
  }

  const switchDownload = (e) => {
    console.log(`switched downlod ${e.event.target.value}, ${e.start}, ${e.end} ${e.streamId}`);
    setDownload({stream: e.streamId, startDateTime: e.start, endDateTime: e.end, mergedVideoFile: e.mergedVideoFile});
  }

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={3}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
          {
            props.topNavSelectedId === titles.app.services.topNavigatorTabs.playback[0] ?
            <Toolbar>
              {
                pastPlaybacks === undefined ?
                <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left"> Loading...</Typography> :
                pastPlaybacks.length > 0 ?
                  <Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} >
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                          >
                            {
                              pastPlaybacks.map(({ cameraName, streamId, startDateTime, endDateTime, userId}) => (
                                <FormControlLabel 
                                  value={`${streamId}_${startDateTime}`} 
                                  control={
                                    <Radio 
                                      onChange={(e) => switchCamera({event: e, streamId: streamId, start: startDateTime, end: endDateTime})}/>} 
                                      label={
                                        <Typography
                                          style={{ wordWrap: "break-word" }}
                                        >
                                            <Typography color="primary">{cameraName}</Typography>
                                            <Typography variant="caption" display="block">
                                            Playback start time: {
                                                new Date(startDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true}) 
                                              }
                                            </Typography>
                                            <Typography variant="caption" display="block">
                                            Playback end time: {
                                                new Date(endDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true}) 
                                              }
                                            </Typography>
                                            {
                                              new Date(endDateTime) > new Date() ? <Typography color="error">happening now</Typography> : null
                                            }
                                        </Typography>
                                      }
                                    />
                              ))}
                          </RadioGroup>
                        </Grid>
                        <Grid item>
                          <Button color="warning" onClick={
                                () => {
                                  console.log(`button pressed`)
                                  setDisplayVideo(false); 
                                 if (new Date(selectedPlayback.endDateTime) > new Date()) {
                                  console.log(` requesting LVE_REPLAY`)
                                  getClip({mode: 'LIVE_REPLAY', stream: 'streamName'})
                                 } else {
                                  console.log(` requesting ON_DEMAND`)
                                  getClip({mode: 'ON_DEMAND', stream: 'streamName'})
                                 }
                                }}
                          >
                              { titles.playback.recordings.onDemandView}</Button>
                          {props.sub === '3418b428-4041-7011-c8f4-471c63de262f' ? <Button onClick={() => { setDisplayVideo(false); getClip({mode: 'LIVE', stream: 'streamName'})}}>{titles.playback.recordings.liveView} </Button> : null }
                        </Grid>
                        { false ? <Grid item >
                            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                                {false ? <Button 
                                  color="secondary" 
                                  onClick={() => { setDisplayVideo(false); getClip({mode: 'LIVE_REPLAY', stream: 'streamName'})}}>{ titles.playback.recordings.livereplayView}
                                </Button> : null }
                              
                              <Button color="warning" onClick={
                                () => {
                                  console.log(`button pressed`)
                                  setDisplayVideo(false); 
                                 if (new Date(selectedPlayback.endDateTime) > new Date()) {
                                  console.log(` requesting LVE_REPLAY`)
                                  getClip({mode: 'LIVE_REPLAY', stream: 'streamName'})
                                 } else {
                                  console.log(` requesting ON_DEMAND`)
                                  getClip({mode: 'ON_DEMAND', stream: 'streamName'})
                                 }
                                }}>{ titles.playback.recordings.onDemandView}</Button>
                              {props.sub === '3418b428-4041-7011-c8f4-471c63de262f' ? <Button onClick={() => { setDisplayVideo(false); getClip({mode: 'LIVE_REPLAY', stream: 'streamName'})}}>{titles.playback.recordings.liveView} </Button> : null }
                            </Typography>
                        </Grid> : null }
                    </Grid>
                  </Grid> : 
                  <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left"> {titles.app.general.empty}</Typography>
              }
            </Toolbar> : props.topNavSelectedId === titles.app.services.topNavigatorTabs.playback[1] ?
            <Toolbar>
            { 
              upcomingPlaybacks === undefined ? <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">Loading... </Typography> :
              upcomingPlaybacks.length > 0 ?
                <Grid>
                  <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} >
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          {upcomingPlaybacks.map(({ cameraName, streamId, startDateTime, endDateTime, stepMachineExArn}) => (
                            <FormControlLabel 
                              value={`${streamId}_${startDateTime}`} 
                              control={
                                <Radio 
                                  onChange={(e) => switchCamera({event: e, streamId: streamId, start: startDateTime, end: endDateTime, stpExArn: stepMachineExArn})}/>} 
                                  label={
                                    <Typography
                                      style={{ wordWrap: "break-word" }}
                                    >
                                        <Typography color="primary">{cameraName}</Typography>
                                        <Typography variant="caption" display="block">
                                        Playback start time: {
                                            new Date(startDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true}) 
                                          }
                                        </Typography>
                                        <Typography variant="caption" display="block">
                                        Playback end time: {
                                            new Date(endDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true}) 
                                          }
                                        </Typography>
                                    </Typography>
                                  }
                              />    
                          ))}
                        </RadioGroup>
                      </Grid>
                      <Grid item>
                        <Button color="warning" onClick={() => { deletePlayback()}}>{ titles.playback.recordings.delete}</Button>
                      </Grid>
                      { false ? <Grid item >
                          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                              {false ? <Button 
                                color="secondary" 
                                onClick={() => { setDisplayVideo(false); getClip({mode: 'LIVE_REPLAY', stream: 'streamName'})}}>{ titles.playback.recordings.livereplayView}
                              </Button> : null }
                            
                            <Button color="warning" onClick={
                                () => {
                                  console.log(`button pressed`)
                                  setDisplayVideo(false); 
                                 if (new Date(selectedPlayback.endDateTime) > new Date()) {
                                  console.log(` requesting LVE_REPLAY`)
                                  getClip({mode: 'LIVE_REPLAY', stream: 'streamName'})
                                 } else {
                                  console.log(` requesting ON_DEMAND`)
                                  getClip({mode: 'ON_DEMAND', stream: 'streamName'})
                                 }
                                }
                              }
                            >{ titles.playback.recordings.onDemandView}</Button>
                            {props.sub === '3418b428-4041-7011-c8f4-471c63de262f' ? <Button onClick={() => { setDisplayVideo(false); getClip({mode: 'LIVE', stream: 'streamName'})}}>{titles.playback.recordings.liveView} </Button> : null }
                          </Typography>
                      </Grid> : null }
                  </Grid>
                </Grid> : 
                <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">{titles.app.general.empty} </Typography>
            }
          </Toolbar> : props.topNavSelectedId === titles.app.services.topNavigatorTabs.playback[2] ?
            <Toolbar>
            {
              older2Hr === undefined ?
              <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left"> Loading...</Typography> :
              older2Hr.length > 0 ?
                <Grid>
                  <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} >
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          {
                            older2Hr.map(({ cameraName, streamId, startDateTime, endDateTime, userId, mergedVideoFile}) => (
                              <FormControlLabel 
                                value={`${streamId}_${startDateTime}`} 
                                control={
                                  <Radio 
                                    onChange={(e) => switchDownload({event: e, streamId: streamId, start: startDateTime, end: endDateTime, mergedVideoFile: mergedVideoFile})}/>} 
                                    label={
                                      <Typography
                                        style={{ wordWrap: "break-word" }}
                                      >
                                          <Typography color="primary">{cameraName}</Typography>
                                          <Typography variant="caption" display="block">
                                          Playback start time: {
                                              new Date(startDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true}) 
                                            }
                                          </Typography>
                                          <Typography variant="caption" display="block">
                                          Playback end time: {
                                              new Date(endDateTime).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: '2-digit', minute: '2-digit', hour12: true}) 
                                            }
                                          </Typography>
                                      </Typography>
                                    }
                                  />
                            ))}
                        </RadioGroup>
                      </Grid>
                      {
                        sharingUrl !== undefined ? 
                        <Grid item xs={12}>
                          <Typography>https://kvsrepository.s3.us-east-1.amazo*****</Typography>
                          <Tooltip title="Copy">
                            <ContentCopyIcon onClick={() => {console.log(`icon clicked`); navigator.clipboard.writeText(sharingUrl);}}/>
                          </Tooltip>
                        </Grid> : null
                      }
                      <Grid item>
                        <Button disabled={disableDownload === true ? true : false} color="warning" onClick={
                              () => {
                                console.log(`button pressed`)
                                console.log(` requesting DOWNLOAD`)
                                getDownload({stream: 'streamName', sharing: false})
                              }}
                        >
                            { titles.playback.download.btnDownload}
                        </Button>
                        <Button disabled={disableDownload === true ? true : false} color="warning" onClick={
                              () => {
                                console.log(`button pressed`)
                                console.log(` requesting DOWNLOAD`)
                                getDownload({stream: 'streamName', sharing: true})
                              }}
                        >
                            { titles.playback.download.shareDownload}
                        </Button>
                      </Grid>
                  </Grid>
                </Grid> : 
                <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left"> {titles.app.general.empty}</Typography>
            }
          </Toolbar>  : 
            null
          }
          {
            displayVideo && hlsurl !== '' ? 
            <Grid item>
              <VideoPlayer autoplay hlsurl={hlsurl}/>
             { false ?  VideoEngineShaka({hlsurl}) : null } 
            </Grid> : 
            null
          }
      </AppBar>
      <Toolbar>
        {
          props.topNavSelectedId === titles.app.services.topNavigatorTabs.playback[0] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.playback.recordings.general}
          </Typography> :
          props.topNavSelectedId === titles.app.services.topNavigatorTabs.playback[1] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.playback.scheduled.general}
          </Typography>
          : props.topNavSelectedId === titles.app.services.topNavigatorTabs.playback[2] ?
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {titles.playback.download.general}
          </Typography> :
          null
        }
      </Toolbar>
    </Paper>
  );
}
